<template>
  <DocumentsGrid
    :documents="documents"
    :downloading-uuids="downloadingUuids"
    @fileClick="handleOpenFile"
  />
</template>

<script>
import applicationService from '@/services/applicationService';
import DocumentsGrid from '@/components/DocumentsGrid.vue';

export default {
  name: 'SubletDocumentsGrid',
  components: {
    DocumentsGrid
  },
  props: {
    documents: {
      type: Array,
      required: true
    },
    applicationId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      downloadingUuids: []
    };
  },
  methods: {
    removeDownloadUuid(uuid) {
      const index = this.downloadingUuids.indexOf(uuid);
      if (index > -1) {
        this.downloadingUuids.splice(index, 1);
      }
    },
    async handleOpenFile(uuid) {
      const applicationId = this.applicationId;
      this.downloadingUuids.push(uuid);

      const newTab = window.open('', '_blank');

      if (!newTab) {
        this.removeDownloadUuid(uuid);
        return;
      }

      try {
        const file = await applicationService.getFile(applicationId, uuid);

        const buffer = file?.buffer;
        const type = file?.type || '';

        if (!buffer) {
          newTab.close();
          return;
        }

        const binaryData = Uint8Array.from(atob(buffer), c => c.charCodeAt(0));
        const blob = new Blob([binaryData], { type });
        const fileURL = URL.createObjectURL(blob);
        newTab.location.href = fileURL;
      } catch (error) {
        newTab.close();
      }

      this.removeDownloadUuid(uuid);
    }
  }
};
</script>

<style></style>
