import Vue from 'vue';
import SwapApp from './SwapApp.vue';
import SubletApp from './SubletApp.vue';
import swapRouter from './router/swapRouter';
import subletRouter from './router/subletRouter';
import swapStore from './store/swap';
import subletStore from './store/sublet';
import * as Sentry from '@sentry/vue';
import '@/assets/styles/index.scss';
import apiClient from './services/apiClient';
import applicationService from './services/applicationService';

Vue.config.productionTip = false;

Vue.prototype.$apiClient = apiClient;
Vue.prototype.$applicationService = applicationService;

let currentApp = '';

if (process.env.VUE_APP_SUBLET || location.host.includes('andrahand')) {
  currentApp = 'sublet';
}

const checkSensitiveCustomer = async store => {
  try {
    await store.dispatch('app/fetchLandlord');

    const landlord = store.state.app.landlord;

    if (landlord?.hasAltEmailAddress) {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

function initSentry(isSensitiveCustomer) {
  if (window.location.hostname !== 'localhost' && !isSensitiveCustomer) {
    Sentry.init({
      Vue,
      dsn: 'https://3e079a61c1b846fa8591f7db278e94ad@o4504678253723648.ingest.sentry.io/4504995352018944',
      environment: process.env.NODE_ENV,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(swapRouter),
          tracePropagationTargets: ['localhost', /^\//]
        })
      ],
      ignoreErrors: [
        'Network Error',
        'Request aborted',
        'Request failed with status code 404',
        'Request failed with status code 403',
        'Request failed with status code 400',
        'Request failed with status code 500'
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production

      tracesSampleRate: 1.0
    });
  }
}

async function initSwapApp() {
  const isSensitiveCustomer = await checkSensitiveCustomer(swapStore);
  initSentry(isSensitiveCustomer);

  new Vue({
    router: swapRouter,
    store: swapStore,
    render: h => h(SwapApp)
  }).$mount('#app');
}

async function initSubletApp() {
  const isSensitiveCustomer = await checkSensitiveCustomer(subletStore);
  initSentry(isSensitiveCustomer);

  new Vue({
    router: subletRouter,
    store: subletStore,
    render: h => h(SubletApp)
  }).$mount('#app');
}

async function main() {
  switch (currentApp) {
    case 'sublet':
      initSubletApp();
      break;
    default:
      initSwapApp();
  }
}

main();
